export enum Experiment {
  SyncDataWithMenuItems = 'specs.membersAreaV2.SyncDataWithMenuItems',
  RemoveUpdatingBoBController = 'specs.responsive-editor.NoMeasureInstall',
  UseAppDataForRoutes = 'specs.membersArea.UseAppDataForRoutes',
  DisableRouteIntegrityCheck = 'specs.membersArea.DisableRouteIntegrityCheck',
  FasterMemberFetching = 'specs.membersAreaV2.fasterMemberFetching',
  SplitInstallation = 'specs.membersArea.SplitInstallation',
  InstalledWidgetsFromRoutes = 'specs.membersArea.installedWidgetsFromRoutes',
  AddWidgetsPluginsResilience = 'specs.membersArea.AddWidgetsPluginsResilience',
  VerticalDeletionRemoveRefreshApp = 'specs.profilePageBoB.VerticalDeletionRemoveRefreshApp',
  EnableSausageBar = 'specs.membersArea.EnableSausageBar',
  OptimizeViewedMemberRolesFetch = 'specs.membersArea.OptimizeViewedMemberRolesFetch',
}
