import type {
  Nullable,
  RouteConfiguration,
  RouteDataContext,
  RouteDataService,
  WixCodeApi,
} from '../../../types';

export class RouteData implements RouteDataService {
  private routes: RouteConfiguration[] = [];
  private settingsPagePrefix: string = '';

  constructor(
    private readonly locationAPI: Pick<WixCodeApi['location'], 'path'>,
  ) {}

  initializeRouteData({ routes, membersAreaPagePrefix }: RouteDataContext) {
    this.routes = routes;
    this.settingsPagePrefix = membersAreaPagePrefix;
  }

  getRouteData() {
    const settingsPagePath = this.getSettingsPagePath();
    const route = this.getRouteByPath(settingsPagePath);

    return {
      slugOrId: null,
      stateId: route?.state ?? null,
      isStatePrivate: route?.private ?? true,
      visibleForRoles: route?.vfr ?? [],
      path: route?.path ?? '',
      visibleWidgetId: route?.widgetId!,
    };
  }

  private getSettingsPagePath(): Nullable<string> {
    const { path } = this.locationAPI;
    const indexOfSettingsPagePrefix = path.indexOf(this.settingsPagePrefix);
    const settingsPagePath = path.slice(indexOfSettingsPagePrefix);

    if (!settingsPagePath.length) {
      return null;
    }

    const settingsPagePathWithoutPrefix = settingsPagePath.slice(1);

    return this.getRoutePath(settingsPagePathWithoutPrefix);
  }

  private getRoutePath(membersPathWithoutPrefix: string[]) {
    const pathContainsSlug = this.pathContainsSlug(membersPathWithoutPrefix);

    if (!pathContainsSlug) {
      return membersPathWithoutPrefix[0];
    }

    return membersPathWithoutPrefix[1];
  }

  private getRouteByPath(routePath: Nullable<string>) {
    return routePath ? this.findRouteByPath(routePath) : this.getDefaultRoute();
  }

  private findRouteByPath(statePath: Nullable<string>) {
    return this.routes.find(({ path }) => path === statePath) ?? null;
  }

  private getDefaultRoute(): RouteConfiguration | null {
    const [firstRoute] = this.routes;
    const homeRoute = this.routes.find(({ home }) => home);
    return homeRoute ?? firstRoute ?? null;
  }

  private pathContainsSlug(membersPathWithoutPrefix: string[]) {
    const routePath = membersPathWithoutPrefix[1];

    return this.routes.some(({ path }) => path === routePath);
  }
}
